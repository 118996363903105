const image_upload_handler_callback = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', '/app/ajax/tiny_upload.php');

    xhr.upload.onprogress = (e) => {
        progress(e.loaded / e.total * 100);
    };

    xhr.onload = () => {
        if (xhr.status === 403) {
            reject({message: 'HTTP Error: ' + xhr.status, remove: true});
            return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            reject('HTTP Error: ' + xhr.status);
            return;
        }
        console.log(xhr.responseText);
        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location != 'string') {
            reject('Invalid JSON: ' + xhr.responseText);
            return;
        }

        resolve(json.location);
    };

    xhr.onerror = () => {
        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
});

const defaultSettings = {
    autosave_ask_before_unload: true,
    autosave_interval: '30s',
    paste_as_text: true,
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    setup: function (editor) {
        const saveContent = function () {
            tinyMCE.activeEditor.save();
            tinyMCE.triggerSave(true, true);
            tinymce.activeEditor.save();
            tinymce.triggerSave(true, true);
            tinyMCE.activeEditor.targetElm.dispatchEvent(new Event('change'));
        };

        editor.on('copy', (event) => {
            const selection = tinyMCE.activeEditor.selection.getContent();
            event.clipboardData.setData('text/plain', selection);
            event.preventDefault();
        });
        editor.on('init', function () {
            if (editor.targetElm) {
                let acc = editor.targetElm.closest('.accordion-item');
                if (acc) {
                    acc.dispatchEvent(new Event("sizeChange"));
                }
            }
        });
        // List of events to attach
        const events = ['paste', 'cut', 'submit', 'input', 'keyup', 'mouseout', 'focusout'];

        // Attach the saveContent function to each event
        events.forEach(function (event) {
            editor.on(event, saveContent);
        });
    }
}

window.nrg.settings = {
    default: {
        //inline: true,
        theme: "silver",
        // content_css: '/assets/style.min.css?v={{ version }}',
        entity_encoding: "raw",
        valid_elements: "*[*]",
        extended_valid_elements: "*[*]",
        plugins: 'importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime lists wordcount help charmap quickbars emoticons',
        menubar: 'edit view insert format tools table help',
        removed_menuitems: 'fontfamily fontsize lineheight',
        toolbar: 'undo redo | blocks | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: false,
        quickbars_selection_toolbar: 'bold italic blocks | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        link_class_list: [
            {title: 'None', value: ''},
            {title: 'Primary', value: 'btn btn-primary'},
            {title: 'Secondary', value: 'btn btn-secondary'},
            {title: 'Tertiary', value: 'btn btn-tertiary'},
        ],
        contextmenu: 'link image imagetools table',
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        height: 600,
        // images upload
        images_upload_url: '/app/ajax/tiny_upload.php',
        images_upload_handler: image_upload_handler_callback,
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp,svg,mp4,bmp,doc,docx,mov',
    },
    simple: {
        theme: "silver",
        //inline: true,
        menubar: false,
        toolbar: 'bold italic underline strikethrough',
        height: 300,
        contextmenu: false,
    }
};

class Tinyator {
    constructor() {
        this.initFromSettings();
        window.addEventListener('tinyator_update', e => {
            tinymce.remove();
            this.initFromSettings();
        });
    }

    initFromSettings() {
        for (let key in window.nrg.settings) {
            let setting = window.nrg.settings[key];
            if (key === 'default')
                setting.selector = '[tinytarget=""]';
            else
                setting.selector = '[tinytarget="' + key + '"]';
            setting = Object.assign({}, defaultSettings, setting)
            tinymce.init(setting);
        }
    }
}

window.nrg.tinyator = new Tinyator();